.product-images-slider .swiper-button-next {
  color: white;
}
.product-images-slider .swiper-button-prev {
  color: white;
}
.product-images-slider .swiper-pagination-bullet {
  opacity: 0.5;
  background-color: #ffffff;
  padding: 5px;
}
.product-images-slider .swiper-pagination-bullet-active {
  opacity: 1;
}
.product-images-slider .swiper-progress-bar {
  width: 600px;
  height: 4px;
  position: relative;
  margin: 20px auto;
  background: #eeeeee;
}
.product-images-slider .swiper-progress-bar .progress {
  height: inherit;
  left: 0;
  top: 0;
  position: absolute;
  background: white;
  z-index: 1;
}
.product-images-slider .swiper-progress-bar .progress-sections {
  left: 0;
  top: 0;
  position: absolute;
  height: inherit;
  width: inherit;
  z-index: 2;
  display: flex;
  flex-direction: row;
}
.product-images-slider .swiper-progress-bar .progress-sections span {
  flex: 1;
  height: inherit;
  border-right: 2px solid #eee;
}
.product-images-slider .swiper-progress-bar .progress-sections span:last-child {
  border-right-width: 0;
}

.representatives-info {
  display: flex;
  flex-direction: column;
  margin: 15px 5px;
}
.representatives-info h5 {
  font-size: small;
  padding: 4px 0 4px 0;
}
.representatives-info a {
  color: #44bb92;
  font-weight: 500;
  font-size: small;
}
.representatives-info a:hover {
  text-decoration-line: underline;
  color: #388e70;
}
.representatives-info a span {
  color: black;
  font-size: 16px;
  margin-right: 8px;
} /*# sourceMappingURL=App.css.map */

/* waves animation start */
.waves {
  background-color: rgb(0, 158, 247);
  position: relative;
  width: 100%;
  height: 100vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 250px;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}
/* waves animation end */
