@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply h-full px-10 py-3 lg:px-12 lg:py-4 text-sm lg:text-base font-medium cursor-pointer;
}

.btn-primary {
  @apply bg-primary-color hover:bg-secondary-color text-white ease-out duration-300;
}

.btn-secondary {
  @apply bg-white hover:bg-secondary-color text-primary-color hover:text-white ease-out duration-300;
}

.btn-hero {
  @apply h-full text-sm lg:text-base font-medium px-8 py-4 lg:px-12 lg:py-6 cursor-pointer bg-secondary-color hover:bg-opacity-60 text-white ease-out duration-300;
}

.navbar-page-btn {
  @apply cursor-pointer text-primary-color px-3 py-5 mx-2  transition-all ease-out duration-500;
}

.navbar-responsive-page-btn {
  @apply text-gray-200  p-5 border-b border-gray-300 cursor-pointer ease-out duration-300;
}

.navbar-responsive-active-page-btn {
  @apply text-primary-light-color p-5 border-b border-gray-300 cursor-pointer ease-out duration-300;
}
